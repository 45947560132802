import { useState,useEffect } from "react";
import gsap,{Power3} from "gsap";
import {  useAllPrismicDocumentsByType } from '@prismicio/react'

function Contact() {
    const [documents] = useAllPrismicDocumentsByType('job_position');
    const mobileYn = window.innerWidth<900?true:false;
    useEffect(()=>{
    
        gsap.fromTo([".c1 .line_t .ani_t"],1,{y:150},{y:0,delay:0.4});

        gsap.fromTo('.deco_img img',1.5,{scale:1.3,ease:Power3.easeIn},{scale:1,ease:Power3.easeIn})
    },[])
    useEffect(()=>{
        console.log(documents);
    },[documents])
  return (
    <div className="contents_wrap">
      <div className="contact c1">
        {!mobileYn&&<p className="main_copy">
        <div className="line_t"><span className="ani_t"><span className="copy_space"></span>We strive to work with the best people to build</span></div>
        <div className="line_t"><span className="ani_t">a better world together through design. </span></div>
        <div className="line_t"><span className="ani_t">If this sounds like you, please send us your CV </span></div>
        <div className="line_t"><span className="ani_t">and a portfolio of your work to our email.</span></div>
        </p>}
        {mobileYn&&<p className="main_copy">
        <div className="line_t"><span className="ani_t"><span className="copy_space"></span>We strive to work with the best people </span></div>
        <div className="line_t"><span className="ani_t">to build a better world together through</span></div>
        <div className="line_t"><span className="ani_t">design. if this sounds like you, please send</span></div>
        <div className="line_t"><span className="ani_t">us your CV and a portfolio of your work</span></div>
        <div className="line_t"><span className="ani_t">to our email.</span></div>
        </p>}
        <div className="company_container">
        <div className="deco_img"><img loading="lazy" src="/img/contact.webp" alt="company interior"/></div>

        <div className="company_section">
            <p className="co_desc">
            솜씨는 실력 있는 디자이너를 항시 모집합니다. 호기심, 연구열, 그리고 우수한 디자인에 대한 공동의 목표를 가지고 함께 나아갈 인재를 환영합니다.
            </p>
            <div className="co_contact_items">
                
                <div className="coc_item">
                    <div className="coc_title">email</div>
                    <div className="coc_contents">somc@somc.co.kr</div>
                </div>
                <div className="coc_item">
                    <div className="coc_title">tel</div>
                    <div className="coc_contents">+82 2 566 0710 </div>
                </div>
                <div className="coc_item">
                    <div className="coc_title">fax</div>
                    <div className="coc_contents">+82 2 515 0710  </div>
                </div>
                <div className="coc_item full_item">
                    <div className="coc_title">address</div>
                    

                    <div className="coc_contents">1-2F, Somc Building, 99, Donggwang-ro 1-gil, <br/>
                    Seocho-gu, Seoul, Republic of Korea 06554</div>
                </div>

            </div>
            <div className="recruit_section">
                <div className="re_title">recruit</div>
                <div className="re_items">
                    {documents&&documents.map((ca,cIndex)=><div key={cIndex} className="re_item">
                        <div className="re_title">{ca.data.position}</div>
                        <div className="re_date">{ca.data.date!==null?ca.data.date:'immediate opening'}</div>
                    </div>)}
                  
                </div>
            </div>
        </div>
        </div>
      </div>
      
    </div>
  );
}

export default Contact;
