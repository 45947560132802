
import './style/common.scss';
import './style/mobile.scss';
import './style/font.css';
import Main from './page/Main'
import Projects from './page/Projects'
import NewsList from './page/NewsList'
import Contact from './page/Contact';
import Header from './component/Header'
import Footer from './component/Footer'
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom'
import ProjectDetail from './page/ProjectDetail';
import NewsDetail from './page/NewsDetail';
function App() {
  return (
    <Router>
      <Header/>
      <div className="move_back">
      <img loading="lazy" className={'logo '+(window.location.pathname==="/"?'active':'')} src="/img/logo.svg"/>
      <svg id='menuMask' className="transition" viewBox="0 0 100 100" preserveAspectRatio="none">

    {window.location.pathname!=="/"&&<path className="path" dur="10s" vectorEffect="non-scaling-stroke" d="M 0 100 V 100 Q 50 100 100 100 V 100 z"/>}
    {window.location.pathname==="/"&&<path className="path" dur="10s" vectorEffect="non-scaling-stroke" d="M 0 100 V 0 Q 50 0 100 0 V 100 z"/>}
     
  </svg>
      </div>
        <Routes>
          <Route exact path="/" element={<Main/>}/>
          <Route exact path="/projects" element={<Projects/>}/>
          <Route exact path="/project/:id" element={<ProjectDetail/>}/>
          <Route exact path="/news" element={<NewsList/>}/>
          <Route exact path="/news/:id" element={<NewsDetail/>}/>
          <Route exact path="/contact" element={<Contact/>}/>

        </Routes>
      <Footer/>
        
    </Router>
  );
}

export default App;
