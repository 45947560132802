import { useState, useEffect, useCallback } from "react";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import gsap from "gsap";
import { Link } from "react-router-dom";
import { menuClick } from '../util/animation'
import { useNavigate } from "react-router-dom";
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import { dateFormat } from "../util/common";
function Projects() {
    const [summaryView, setSummaryView] = useState(window.innerWidth < 900 ? false : true);
    const navigate = useNavigate();
    const [documents] = useAllPrismicDocumentsByType('project');
    const [sortedProject, setSortedProject] = useState([]);
    const [summaryProject, setSummaryProject] = useState({});

    useEffect(() => {
        if (documents) {
            const tempAr = [...documents];

            tempAr.sort((a, b) => b.data.year - a.data.year);
            let yearItemAr = [];
            let yearIndex = 0;
            //const prAr = [];
            const prAr = [...tempAr.reduce((ar, pr) => {

                if (ar.length === 0) {
                    yearItemAr.push(pr);
                    ar.push(yearItemAr)
                    return ar;

                } else {
                    const y = yearItemAr[0].data.year
                    const pr_y = pr.data.year

                    if (y === pr_y) {
                        yearItemAr.push(pr);

                        ar[yearIndex] = yearItemAr;
                        return ar;
                    } else {
                        yearItemAr = [];
                        yearIndex++;
                        yearItemAr.push(pr);
                        ar.push(yearItemAr);
                        return ar;
                    }


                }

            }, [])];
            setSortedProject(prAr);
            setSummaryProject(prAr[0][0]);
            //console.log(prAr[0][0].data);

        }
    }, [documents])

    useEffect(() => {
        if (documents) {
            const pr_img_section = document.querySelector('.pr_img_section');
            if (window.innerWidth > 900) {
                const swiper = new Swiper('.pr_img_section', {
                    // Optional parameters

                    slidesPerView: 'auto',
                    spaceBetween: 20,

                });


                pr_img_section.addEventListener('mousemove', moreBtMove)
                pr_img_section.addEventListener('mouseenter', moreBtShow)
                pr_img_section.addEventListener('mouseleave', moreBtHide)
            }


            return () => {
                if (window.innerWidth > 900) {
                    pr_img_section.removeEventListener('mousemove', moreBtMove);
                    pr_img_section.removeEventListener('mouseenter', moreBtShow);
                    pr_img_section.removeEventListener('mouseleave', moreBtHide);
                }


            }
        }


    }, [documents])
    const moreBtMove = useCallback((e) => {
        gsap.to('div.more_bt', 0, { css: { left: e.clientX, top: e.clientY } });
    }, []);
    const moreBtShow = useCallback((e) => {
        gsap.to('div.more_bt', 0.4, { scale: 1 });
    }, []);
    const moreBtHide = useCallback((e) => {
        gsap.to('div.more_bt', 0.4, { scale: 0 });
    }, []);
    const projectClick = (pr) => {
        console.log(summaryProject);
        setSummaryProject(pr);
        if (window.innerWidth < 900) {

            setSummaryView(true);
        } else {

            gsap.fromTo(".pri_item img ",0.5,{width:0},{width:"100%"});

        }

    }
    return (
        <div className="contents_wrap">
            <div className="projects">
                <div className="pr_items">
                    {sortedProject.map((yi, yiIndex) => <div className="pr_item" key={yiIndex}>
                        <div className="year_item">{yi[0].data.year}</div>
                        <div className="title_items">
                            {yi.map((pr, prIndex) => <div key={prIndex} className={"title_item "+(pr.data.title_en===summaryProject.data.title_en?'active':'') }onClick={() => { projectClick(pr) }}>
                                <div className="title_deco ">
                                    <div className="td_item"></div>
                                    <div className="td_item"></div>
                                    <div className="td_item"></div>
                                    <div className="td_item"></div>
                                </div>
                                <div className="title_text">
                                    <div className="title_kr">{pr.data.title_kr}</div>
                                    <div className="title_en">{pr.data.title_en}</div>
                                </div>
                            </div>)}

                        </div>
                    </div>)}


                </div>
                <div className="title_items">


                </div>
                {summaryView&&<div className="project_summary">
                    <div className="more_bt">
                        more<br />
                        detail
                    </div>
                    <button className="default_bt back_bt" onClick={() => { setSummaryView(false) }}>back list</button>
                    <div className="section_title">Project Summary</div>
                    <div className="pr_info">
                        <div className="pr_title"><div>{summaryProject.data&&summaryProject.data.title_en}</div>
                            <Link to="/project/33"><button className="default_bt more_bt" onClick={() => { menuClick(navigate, 'project/'+summaryProject.uid) }}>more info</button></Link>
                        </div>
                        <div className="pr_subinfo_items">
                            <div className="prs_item">
                                year<br />
                                {summaryProject.data&&summaryProject.data.year}
                            </div>
                            <div className="prs_item">
                                category<br />
                                {summaryProject.data&&summaryProject.data.category}
                            </div>
                        </div>
                    </div>

                    <div className="pr_img_section">
                        <div className="swiper-wrapper">

                            {summaryProject.data !== undefined &&<div className="pri_item pri_1 swiper-slide" onClick={() => { menuClick(navigate, 'project/'+summaryProject.uid) }}>

                                <img loading="lazy" src={summaryProject.data.thumbnail1.url} />

                            </div>}
                            {summaryProject.data !== undefined &&<div className="pri_item pri_2 swiper-slide" onClick={() => { menuClick(navigate, 'project/'+summaryProject.uid) }}>

                                <img loading="lazy" src={summaryProject.data.thumbnail2.url} />

                            </div>}
                        </div>
                    </div>
                    <div className="pr_desc">
                    {summaryProject.data&&summaryProject.data.desc[0].text}
                    </div>
                    <div className="bt_section mobile_view">
                        <button className="default_bt more_bt" onClick={() => { menuClick(navigate, 'project/'+summaryProject.uid) }}>more info</button><br />
                        <br /><br /><br />
                        <button className="default_bt back_bt" onClick={() => { setSummaryView(false) }}>back list</button>
                    </div>
                </div>}

            </div>
        </div>
    );
}

export default Projects;
