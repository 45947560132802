import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'somc'
//export const repositoryName = 'somc'
const endpoint = prismic.getEndpoint(repositoryName)
export const client = prismic.createClient(endpoint, {
  // If your repository is private, add an access token
  accessToken: 'MC5ZdVl6dFJBQUFDWUFxNmxY.77-977-9XXbvv70D77-9fu-_ve-_ve-_vXnvv73vv73vv73vv73vv73vv71M77-9c3Dvv71R77-9RwkSOTfvv70M',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
   
  ],
})