import { useEffect,useState } from "react";
import OtherProjects from "../component/otherProjects";
import gsap, { Linear, Power2,Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imagesLoaded from 'imagesloaded';
import {  useSinglePrismicDocument } from '@prismicio/react'
gsap.registerPlugin(ScrollTrigger)


function Main() {
  const mobileYn = window.innerWidth<900?true:false;
  const [loadMainComplete,setLoadMainComplete] = useState(false);
  const [team] = useSinglePrismicDocument('team_image');
  
  const initAni = ()=>{
    const path_tl = gsap.timeline();
    path_tl.to('#menuMask path', 0.4, { attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" }, ease: Power4.easeIn });
    path_tl.to('.move_back .logo', 0, { opacity:0});
    path_tl.to('#menuMask path', 0.3, { attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" }, ease: Power4.easeOut });
    //인트로 무비
    
    gsap.fromTo([".m1 .main_copy .ani_t",".m1 .main_copy .ani_int_t"],1,{y:200},{y:0,delay:0.6});

    gsap.fromTo(".m1 .main_img .back_img", 1, { y:200, ease: Power4.easeIn }, {y:0, ease: Power4.easeIn });
    gsap.fromTo(".m1 .main_img .back_img", 2, { scale: 1.2, ease: Power2.easeOut }, {delay:1, scale: 1, ease: Power2.easeOut });
  }
  useEffect(() => {
    const preloadImage = new Promise((resolve, reject) => {
      imagesLoaded(document.querySelectorAll(".main.m1 img"), resolve);
    });
  

    if(!loadMainComplete){
      Promise.all([preloadImage]).then(()=>{
        setLoadMainComplete(true);
        initAni();
      });
  
    }else{
      initAni();
    }
   
    
    
    gsap.to('.arrow_down', {
      opacity: 0,
      scrollTrigger: {
        trigger: ".m1",
        start: "top top",
        end: "+=100",
        scrub: 1,




      }
    })

    const ani_t_ar = [...document.getElementsByClassName('mc1')[0].getElementsByClassName('ani_t')].sort(() => Math.random() - 0.5);;
    const ani_t_ar2 = [...document.getElementsByClassName('mc2')[0].getElementsByClassName('ani_t')].sort(() => Math.random() - 0.5);;
    const ani_t_ar3 = [...document.getElementsByClassName('mc3')[0].getElementsByClassName('ani_t')].sort(() => Math.random() - 0.5);;


    gsap.to(".m1 .sc2", {
      opacity:1,y:100, scrollTrigger: {
        trigger: ".sc2",
        start: "-="+(mobileYn?400:800),
        end: "-="+(mobileYn?200:500),
        scrub: true,


      }
    })
    // gsap.to(ani_t_ar, {
    //   opacity:1,scrollTrigger: {
    //     trigger: ".m1",
    //     start: "+=100",
    //     end:"-=100"


    //   }
    // })


    let back_img_tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".sc2",
        start: "-="+(mobileYn?400:900),
        end: "-="+(mobileYn?100:200),
        scrub: true
      }
    });
    back_img_tl.to('.back_img.bi1',{opacity:0},0);
    back_img_tl.to('.back_img.bi2',{opacity:2},0);



    
    let mc_tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".m1",
        start: "+=200",
        end: "+="+(mobileYn?600:2700),
        scrub: 1,
       // ease: Linear.easeNone,

      }
    });

    mc_tl.to(ani_t_ar2, { opacity: 0, }, 0);
    mc_tl.to(ani_t_ar3, { opacity: 0, }, 0);
    mc_tl.to(ani_t_ar, {
      opacity: 0,
      stagger: 0.3,
    }, 0);
    //mc_tl.to('.mc1', { opacity: 0 }, 2);
    mc_tl.fromTo('.mc2', { opacity: 0 }, { opacity: 1, }, 2);
    mc_tl.to(ani_t_ar2, {

      opacity: 1,
      stagger: 1,


    }, 3);
    mc_tl.to(ani_t_ar2, {
      opacity: 0,
      stagger: 0.4,

    }, 15);
    mc_tl.to('.mc2', { opacity: 0 }, 16);
    mc_tl.fromTo('.mc3', { opacity: 0 }, { opacity: 1, }, 16);
    mc_tl.to(ani_t_ar3, {

      opacity: 1,
      stagger: 1,


    }, 17);

    gsap.to(".m1 .main_copy", {
      y: mobileYn?-220:-window.innerHeight/1.5, scrollTrigger: {
        trigger: ".m2",
        start: "-="+(mobileYn?150:200),
        end: "+="+(mobileYn?300:300),
        scrub: true,
      }
    })
   
    if(!mobileYn){
    
        gsap.to(".team_img img",1, {
          scale:1, scrollTrigger: {
           trigger: ".team_section",
  
         }
        })
  
   

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".business_section",
        pin: true,   // pin the trigger element while active
        start: "-=" + window.innerHeight / 4.5, // when the top of the trigger hits the top of the viewport
        end: "+=4000", // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        pinSpacing: false,


      }
    });


    tl.fromTo('.bs_img_items', { width:window.innerWidth,y: 500 }, {width:520, y: 0 }, 0)
    tl.fromTo('.bt1 .bs_t', { y: 200 }, { y: 0 }, 0);


    for (let i = 1; i <= 5; i++) {

      tl.to('.bt' + i + ' .bs_t', { y: 200 }, i);
      tl.fromTo('.bt' + (i + 1) + ' .bs_t', { y: 200 }, { y: 0 }, i);
      tl.to('.bi' + (7 - i), { css: { height: 0 } }, i)
    }
    
    }

  }, [])
  return (
    <div className="contents_wrap">
      <div className="main m1">
        {!mobileYn&&<div className="main_copy mc1">
          <div className="line_t"><span className="copy_space"></span><span className="ani_int_t">We</span> <span className="ani_t">are</span> <span className="ani_t">a multi-disciplinary</span> <span className="ani_t">design</span> </div>
          <div className="line_t"><span className="ani_t">studio</span> <span className="ani_t">focuses on</span>  <span className="ani_t">architecture</span></div>
          <div className="line_t"><span className="ani_t">and landscaping</span></div>
        </div>}
        {mobileYn&&<div className="main_copy mc1">
          <div className="line_t"><span className="copy_space"></span><span className="ani_int_t">We</span> <span className="ani_t">are</span> <span className="ani_t">a multi-disciplinary</span>  </div>
          <div className="line_t"><span className="ani_t">design</span> <span className="ani_t">studio</span>   </div>
          <div className="line_t"><span className="ani_t">focuses </span> <span className="ani_t">on architecture</span></div>
          <div className="line_t"> <span className="ani_t">and</span> <span className="ani_t">landscaping</span></div>
        </div>}
        {!mobileYn&&<div className="main_copy mc2">
          <span className="copy_space copy_space3"></span> <span className="ani_t">challenge to</span> <span className="ani_t">build a world</span> <span className="ani_t">to be a</span><br />
          <span className="ani_t">better place to</span> <span className="ani_t">live using</span>  <span className="ani_t">our own innovative</span><br />
        <span className="ani_t">design resources</span> <span className="ani_t">and technology</span>
        </div>}
        {mobileYn&&<div className="main_copy mc2">
        <span className="copy_space copy_space3"></span> <span className="ani_t">challenge to</span> <span className="ani_t">build a </span>
         <span className="ani_t">world to be a</span> <span className="ani_t">better place to</span><br />
          <span className="ani_t">live using</span>  <span className="ani_t">our own innovative</span><br />
        <span className="ani_t">design resources and</span> <span className="ani_t"> technology</span>
        </div>}
        {!mobileYn&&<div className="main_copy mc3">
          <span className="copy_space copy_space3"></span> <span className="ani_t">propose</span> <span className="ani_t">an integrated</span> <span className="ani_t">solution</span><br />
          <span className="ani_t">which is</span>  <span className="ani_t">not confined to</span> <span className="ani_t">a single area</span><br />
         <span className="ani_t">but encompasses</span> <span className="ani_t">multiple areas</span>
        </div>}
        {mobileYn&&<div className="main_copy mc3">
        <span className="copy_space copy_space3"></span> <span className="ani_t">propose</span> <span className="ani_t">an integrated</span> <br/>
        <span className="ani_t">solution </span> <span className="ani_t">which is</span>  <span className="ani_t">not confined </span><br/>
         <span className="ani_t">to a single area</span>  <span className="ani_t">but</span> 
        <span className="ani_t">encompasses multiple areas</span>
        </div>}
        <div className="sub_info">
          <div className="si_item">since 1985</div>
          <div className="si_right_section">
            <div className="si_item">in Korea</div>
            <div className="si_item">ENVIRONMENTAL design</div>
          </div>
        </div>
        <div className="main_img">
          <img loading="lazy" className="back_img bi1" src={"/img/main01"+(mobileYn?'_m':'')+".webp" }  alt="main image"/>
          <img loading="lazy" className="back_img bi2" src={"/img/main0102"+(mobileYn?'_m':'')+".webp"}  alt="main image2"/>
          <img loading="lazy" className="arrow_down" src="/img/arrow_down.svg" />
          <p className="sub_copy">
          1985년 이래로 한국의 주거 환경 디자인의 발전을 이끌어온 ‘솜씨’는 차별화된 디자인으로 자연과 공생하며 살기 좋은 도시 환경과 주거 문화의 트렌드를 선도합니다.  
          </p>
          <p className="sub_copy sc2">
          한국의 주거 디자인 역사에서 아파트 외관 디자인의 발전을 선도해온 ‘솜씨’의 위상은 꾸준히 한 길만을 달려온 과정과 결과물이라 자부합니다. 더 나아가 우리는 ‘솜씨’만의 디자인 노하우와, 기술력을 바탕으로 
다가올 미래에 살기 좋은 지구촌을 형성하기 위해 열정과 혁신으로 도전합니다.
          </p>
        </div>
      </div>
      <div className="main m2">
        <div className="business_section">
          <div className="business_desc">
            <div className="bd_tiem">
              Architectural design<br />
              Interior design<br />
              Architectural color design<br />
            </div>
            <div className="bd_tiem">
              Landscape facility design<br />
              Public facilities design<br />
              Construction, supervision<br />
            </div>
          </div>
          <div className="bs_title_items">
            <div className="bs_title_item bt1">
              <div className="bs_title_container"><div className="bs_t">Architectural design</div></div>
            </div>
            <div className="bs_title_item bt2">
              <div className="bs_title_container"><div className="bs_t">Interior design</div></div>
            </div>
            <div className="bs_title_item bt3">
              <div className="bs_title_container"><div className="bs_t">Architectural color design</div></div>
            </div>
            <div className="bs_title_item bt4">
              <div className="bs_title_container"><div className="bs_t">Landscape facility design</div></div>
            </div>
            <div className="bs_title_item bt5">
              <div className="bs_title_container"><div className="bs_t">Public facilities design</div></div>
            </div>
            <div className="bs_title_item bt6">
              <div className="bs_title_container"><div className="bs_t">Construction, supervision</div></div>
            </div>

          </div>
          <div className="bs_img_items">
            <div className="bs_img_item bi1"><img loading="lazy" src="/img/main_bs6.webp" alt="our business" /></div>
            <div className="bs_img_item bi2"><img loading="lazy" src="/img/main_bs5.webp" alt="our business"/></div>
            <div className="bs_img_item bi3"><img loading="lazy" src="/img/main_bs4.webp" alt="our business"/></div>
            <div className="bs_img_item bi4"><img loading="lazy" src="/img/main_bs3.webp" alt="our business"/></div>
            <div className="bs_img_item bi5"><img loading="lazy" src="/img/main_bs2.webp" alt="our business"/></div>
            <div className="bs_img_item bi6"><img loading="lazy" src="/img/main_bs1.webp" alt="our business"/></div>
          </div>
        </div>
        <div className="team_section">
          <div className="team_img"><img loading="lazy" src={team&&team.data.team.url}  alt="our team"/></div>
          <p className="team_desc">
          솜씨는 1985년에 설립된 자유롭고 창의적인 디자인 스튜디오로서, 건축 특화 디자인, 인테리어 설계 시공, 조경시설물 제작 및 공공시설물 디자인, 건축 색채 디자인 등 하나의 분야에 국한되지 않고 사이트 특색에 맞는 통합적인 디자인을 제안합니다. 우리는 각 프로젝트의 고유한 특성에 입각하여 다양한 규모의 작업에 착수하며, 혁신적이고 상상력이 풍부한 디자인을 바탕으로 지속 가능한 설계 및 디자인을 강조합니다.

          </p>

        </div>
      </div>
      <div className="project_recent_section">
        <div className="pr_section_title">Recent Projects</div>
        <OtherProjects />
      </div>
    </div>
  );
}

export default Main;
