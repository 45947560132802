
import { useEffect } from "react";
import OtherProjects from "../component/otherProjects";
import {  usePrismicDocumentByUID } from '@prismicio/react'
import { useLocation } from "react-router-dom";

function ProjectDetail() {
    const location = useLocation();
    const [document] = usePrismicDocumentByUID('project', location.pathname.substring(9,999))
   
   

    
    useEffect(()=>{
        console.log(document);
    },[document])
    
    return (
        <div className="contents_wrap">
            <div className="project_detail pd1">
                <div className="top_img">
                    <img loading="lazy" src={document&&document.data.top_image.url} />
                </div>
                <div className="pr_info">
                    <div className="pr_title"> {document&&document.data.title_en}</div>
                    <div className="pr_info_right_section">
                        <div className="pr_info_items">
                            <div className="pr_info_item">
                                location<br />
                                {document&&document.data.location}
                            </div>
                            <div className="pr_info_item">
                                area<br />
                                {document&&document.data.scale}㎡ 
                            </div>
                            <div className="pr_info_item">
                                year<br />
                                {document&&document.data.year}
                            </div>
                            <div className="pr_info_item">
                                category<br />
                                {document&&document.data.category}
                            </div>

                        </div>
                        <div className="pr_desc">
                        {document&&document.data.desc.map((dp,dpIdnex)=><p key="dpIndex">{dp.text}</p>)}
                        </div>
                    </div>
                </div>
            </div>
            {document&&<div className="project_detail pd2">
                <div className="thumbnail_img">
                    <img loading="lazy" className="thumb1" src={document.data.thumbnail1.url} />
                    <img loading="lazy" className="thumb2" src={document.data.thumbnail2.url} />
                </div>
                <div className="contents_imgs">

                    {document.data.contents_image.map((cimg,cIndex)=><img key={cIndex} loading="lazy" src={cimg.body_img.url} />)}
                    
                </div>
            </div>}
            <div className="project_detail pd3">

                <div className="project_recent_section">
                    <div className="pr_section_title">Other Projects</div>
                    <OtherProjects currentPr={document&&document.uid}/>
                </div>
            </div>
        </div>
    );
}

export default ProjectDetail;
