import { useState,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {menuClick} from '../util/animation'
import {  useAllPrismicDocumentsByType } from '@prismicio/react'
import { dateFormat } from "../util/common";

function NewsList() {
    const navigate = useNavigate();
    const [documents] = useAllPrismicDocumentsByType('news');
    const [sortedNews,setSortedNews] = useState([]);
    const [maxNewsLength,setMaxNewsLength]= useState(20);
    useEffect(()=>{
        console.log(documents);
       if(documents){
        const tempAr= [...documents];
        setSortedNews(tempAr.sort((a, b) =>  new Date(b.data.date)- new Date(a.data.date)).filter((n,nIndex)=>nIndex<maxNewsLength))
       }
    },[documents,maxNewsLength])
    return (
        <div className="contents_wrap">
            <div className="news_list">
                <div className="section_title">news</div>
                <div className="news_items">
                    {documents&&sortedNews.filter((nItem,nIndex)=>nIndex<maxNewsLength).map((news,nIndex)=><div key={nIndex} className="news_item" onClick={()=>{menuClick(navigate,'news/'+news.uid)}}>
                        <div className="news_cate">{news.data.category}</div>
                        <div className="news_title">{news.data.title}</div>
                        <div className="news_date">{dateFormat(news.data.date)}</div>
                    </div>)}
                </div>
              
                {(documents&&documents.length>maxNewsLength&&maxNewsLength<50)&&<div className="more_bt_section">
                    <a className="default_bt more_bt" onClick={()=>{setMaxNewsLength(9999)}}>Load more...</a>
                </div>}
            </div>
        </div>
    );
}

export default NewsList;
