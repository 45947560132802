import {menuClick} from '../util/animation'
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import {  usePrismicDocumentByUID } from '@prismicio/react'
import { useLocation } from "react-router-dom";
import { dateFormat } from '../util/common';

function NewsDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const [document] = usePrismicDocumentByUID('news', location.pathname.substring(6,999))
   
    useEffect(()=>{
        
        console.log(document);
    },[document])
    return (
        <div className="contents_wrap">
            <div className="news_detail">
                <div className="news_left_section">
                    <button className="default_bt back_bt" onClick={()=>{menuClick(navigate,'news')}}>back list</button>
                </div>
                <div className="news_contents_section">
                    <div className="news_header">
                        <div className="news_title">{document&&document.data.title}</div>
                        <div className="news_info">
                            <div className="info_item">{document&&document.data.category}</div>
                            <div className="info_item">{document&&dateFormat(document.data.date)}</div>
                        </div>
                    </div>
                    <div className="news_img">
                        <img loading="lazy" src={document&&document.data.image.url} />
                    </div>
                    <div className="news_contents">
                        {/* <div dangerouslySetInnerHTML={{__html:}}></div> */}
                        {document&&document.data.contents.map((dp,dpIdnex)=><p key="dpIndex">{dp.text}</p>)}
                        <div className="bt_section">
                            <button className="default_bt back_bt" onClick={()=>{menuClick(navigate,'news')}}>back list</button>
                        </div>
                    </div>


                </div>
                <div className="news_right_section">
                </div>
            </div>
        </div>
    );
}

export default NewsDetail;
