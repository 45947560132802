import { useEffect,useState } from "react";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import {menuClick} from '../util/animation'
import { useNavigate } from "react-router-dom";
import { useAllPrismicDocumentsByType } from '@prismicio/react'
function OtherProjects(props) {
    const navigate  = useNavigate();
    const [documents] = useAllPrismicDocumentsByType('project');
    const [sortedProject, setSortedProject] = useState([]);

    useEffect(() => {
        if (documents) {
            const tempAr = [...documents];

           
            setSortedProject( tempAr.sort((a, b) => b.data.year - a.data.year).filter((prd)=>prd.uid!==props.currentPr).filter((pr,pIndex)=>pIndex<5));
            const swiper = new Swiper('.pr_recent_items', {
                // Optional parameters
                slidesPerView: 'auto',
                spaceBetween: window.innerWidth<900?15:20,
              
              });
        }
    },[documents,props.currentPr])
   
    return (
        <div className="pr_recent_items">
            <div className="swiper-wrapper">
            {sortedProject.map((pr,prIndex)=><div key={prIndex} className="pr_item swiper-slide"  onClick={()=>{menuClick(navigate,'project/'+pr.uid)}}> 
                <div className="pr_img"><img loading="lazy" src={pr.data.contents_image[0].body_img.url} /></div>
                <div className="pr_info">
                    <div className="pr_title">
                        <div className="prt_en">{pr.data.title_en}</div>
                        <div className="prt_kr">{pr.data.title_kr}</div>
                    </div>
                    <div className="pr_subinfo">
                        <div className="prs_year">{pr.data.year}</div>
                        <div className="prs_cate">{pr.data.category}</div>
                    </div>
                </div>
            </div>)}
          
            </div>
        </div>
    );
}

export default OtherProjects;
