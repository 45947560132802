import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import gsap, { Power4 } from "gsap";
import {menuClick} from '../util/animation'
function Header() {
  let location = useLocation();
  const navigate = useNavigate();
  const [mobileMenuActive,setMobileMenuActvie] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileMenuActvie(false);
  }, [location])

 

  return (
    <header className={"header"+(mobileMenuActive?' active':'')}>
      <div className="logo" onClick={() => { menuClick(navigate,'') }}><img loading="lazy" src="/img/logo.svg"  alt="logo"/></div>
      <div className="mobile_menu" onClick={()=>{setMobileMenuActvie(!mobileMenuActive)}}>{mobileMenuActive?'CLOSE':'MENU'}</div>
      <ul className="main_menu">
        <li className={(location.pathname==='/projects'||location.pathname.indexOf('/project')>=0)?'active':''} onClick={() => { menuClick(navigate,'projects') }}>
          Projects
        </li>
        <li  className={(location.pathname.indexOf('/news')>=0)?'active':''} onClick={() => { menuClick(navigate,'news') }}>
          News
        </li>
        <li  className={location.pathname==='/contact'?'active':''} onClick={() => { menuClick(navigate,'contact') }}>
          Contact
        </li>
      </ul>
      <div className="company_info mobile_view">
      1-2F, Somc Building, 99, Donggwang-ro 1-gil, <br/>
             Seocho-gu, Seoul, Republic of Korea 06554 <br/>
Tel) +82 2 566 0710 <br/>
Fax) +82 2 515 0710  
      </div>

    </header>
  );
}

export default Header;
