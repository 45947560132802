import gsap,{Power4}from "gsap";


const menuClick = (navi,target) => {
    console.log(window.location.pathname,target)
    if(window.location.pathname==='/'+target) return 0;
    console.log(window.location.pathname);
    if(target==='/'){
        pageIntroChangeAni();
    }else{
        pageChangeAni();
    }
  
    setTimeout(() => {
        navi('/' + target);
    }, 700)
  }

  const pageIntroChangeAni = () => {
    const path_tl = gsap.timeline();
    path_tl.to('#menuMask path', 0.5, { attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" }, ease: Power4.easeIn });
    path_tl.to('.move_back .logo', 0, { opacity:1});
    path_tl.to('#menuMask path', 0.2, { attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" }, ease: Power4.easeOut });
  }
const pageChangeAni = () => {
    const path_tl = gsap.timeline();
    path_tl.to('#menuMask path', 0.5, { attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" }, ease: Power4.easeIn });
    path_tl.to('.move_back .logo', 0, { opacity:1});
    path_tl.to('#menuMask path', 0.2, { attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" }, ease: Power4.easeOut });
    path_tl.to('#menuMask path', 0.4, { attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" }, ease: Power4.easeIn });
    path_tl.to('.move_back .logo', 0, { opacity:0});
    path_tl.to('#menuMask path', 0.3, { attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" }, ease: Power4.easeOut });
  }


  export {menuClick,pageChangeAni}