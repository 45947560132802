

function Footer() {
    return (
      <footer className="footer">
        <div className="left_section">
        <div className="company_title">
         SOMC<br/>
         Architecture<br/>
         Design Studio
         </div>
         <p className="company_desc">
         SOMC has been leading the design trend of urban architectural design and residential culture in Korea, since 1985.  We take on various scope of projects and focus on the uniqueness of each project through innovative and creative design that is sustainable.  
         </p>
        </div>
        <div className="right_section">
            <div className="company_contact">
            1-2F, Somc Building, 99, Donggwang-ro 1-gil, <br/>
             Seocho-gu, Seoul, Republic of Korea 06554 <br/>
Tel) +82 2 566 0710 <br/>
Fax) +82 2 515 0710  
            </div>
            <div className="company_sns">@Insta</div>
            <div className="company_copyright">Copyright@SOMC, Ltd. All rights reserved</div>
        </div>

        
      </footer>
    );
  }
  
  export default Footer;
   